'use client';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import AxiosService from '@/app/services/AxiosServices';
import { setIsLoading, setItems } from '@/app/redux.actions/items.actions';
import { useAppContext } from '@/app/context';

import CardItem from '../card-item/CardItem';
import '../../scss/pages/home/list_items.scss';
import { Spinner } from '../spinner/Spinner';

import mapItems from '@/app/helpers/formatItems';
import modifyDatabaseCart from '@/app/helpers/modifyDatabaseCart';

const ListItems = () => {
    const axiosService = new AxiosService();
    const dispatch = useDispatch();
    const { items: storeItems, cart: storeCart, stepSideNav } = useSelector(
        (state) => state.appStore
    );
    const [isLoadingData, setIsLoadingData] = useState(false); 
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    useEffect(() => {
        const fetchItems = async () => {
            try {
                setIsLoadingData(true);
                const response = await axiosService.getData('/products');
                const mappedItems = mapItems(response);
                dispatch(setItems(mappedItems));
            } catch (error) {
                console.error('Error fetching items:', error);
            } finally {
                setIsLoadingData(false);
            }
        };
        fetchItems();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        if (!isInitialLoad) {
            modifyDatabaseCart(storeCart, dispatch, stepSideNav);
        } else {
            setIsInitialLoad(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storeCart]);

    return (
        <div className="main_list_items_container" id="list_items">
            <div className="text_container">
                <h3 className="subtitle">Artículos</h3>
                <p className="description">Imágenes de referencia.</p>
            </div>

            <div className="list_container">
                {isLoadingData ? (
                    <Spinner />
                ) : (
                    storeItems &&
                    storeItems.map((item, index) => (
                        <CardItem item={item} index={index} key={index} />
                    ))
                )}
            </div>
        </div>
    );
};

export default ListItems;
