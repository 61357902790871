import '../../scss/components/_tooltip.scss';

const Tooltip = ({
    txtHover,
    position,
    children,
}) => {
    return (
        <div className={`tooltip__container`}>
            <span className={`tooltip__text tooltip--${position}`}>{ children }</span>
            <span className="tooltip__trigger">
                {txtHover}
                <span className="tooltip__icon">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11.458"
                        height="11.458"
                        viewBox="0 0 11.458 11.458"
                        aria-label="Información"
                    >
                        <path
                            id="ic_info_24px"
                            d="M7.729,2a5.729,5.729,0,1,0,5.729,5.729A5.731,5.731,0,0,0,7.729,2ZM8.3,10.594H7.156V7.156H8.3Zm0-4.583H7.156V4.865H8.3Z"
                            transform="translate(-2 -2)"
                            fill="#adb6c4"
                        />
                    </svg>
                </span>
            </span>
        </div>
    );
};

export default Tooltip;