import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

const CardItem = ({ item, index }) => {

 

    return (
        <div key={index} className="item_container">
            <div
                className="item"
                key={item?.id}
                style={{
                    backgroundImage: `url(${process.env.NEXT_PUBLIC_API_URL}/uploads/products/${item?.background})`,
                }}
            > 
                <Link href={`/products/${item?.id}`}>
                    <Image
                        src={`${process.env.NEXT_PUBLIC_API_URL}/uploads/products/${item?.images[0]}`}
                        alt={item?.name}
                        width={1000}
                        height={1000}
                    />
                </Link>
            </div>

            <h3 className="item_info">{item?.name}</h3>
        </div>
    );
};

export default CardItem;
