/* eslint-disable @next/next/no-img-element */
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import '../../scss/components/_file_upload.scss';
import { timerAlert } from '@/app/helpers/alerts';



function ImageUpload({
    images, setImages
}) {
    const [] = useState([]);

    const onDrop =  useCallback(
        async  (acceptedFiles) => {

            
            if (images.length + acceptedFiles.length > 2) {
                await timerAlert( 'Error', '¡Puedes subir como máximo dos imágenes!', 'error', 3000);
                return;
            }

            const newImages = acceptedFiles.map((file) => {
                return Object.assign(file, {
                    preview: URL.createObjectURL(file),
                });
            });

            setImages((prevImages) =>
                [...prevImages, ...newImages].slice(0, 2)
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [images]
    );
    

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpg', '.jpeg'],
          }
        // maxFiles: 2,
    });

    const removeImage = (index) => {
        const newImages = [...images];
        newImages.splice(index, 1);
        setImages(newImages);
    };


    return (
        <section className="upload_container">
            <div {...getRootProps()} className="dropzone">
                <input {...getInputProps()} />
                <p> Arrastra y suelta 1 o 2 imágenes donde se evidencie el defecto de tu producto.</p>
            </div>
            <aside className="preview">
                {images.map((file, index) => (
                    <div key={file.name} className="image_preview">
                        <img
                            src={file.preview}
                            alt={`preview ${index}`}
                            className="image"
                        />
                        <button onClick={() => removeImage(index)} className='remove_button' >
                            X
                        </button>
                    </div>
                ))}
            </aside>
        </section>
    );
}

export default ImageUpload;
