'use client';
import { useState, useEffect } from 'react';
import { confirmAlert, timerAlert } from '@/app/helpers/alerts';
import '../../scss/pages/home/form-request.scss';
import AxiosService from '@/app/services/AxiosServices';
import { useSelector, useDispatch } from 'react-redux';
import { setIsLoading, setItems } from '@/app/redux.actions/items.actions';
import mapItems from '@/app/helpers/formatItems';
import { Spinner } from '../spinner/Spinner';
import Tooltip from '../tooltip/Tooltip';
import { tr } from 'date-fns/locale';
import ImageUpload from './ImageUpload';

const FormRequest = () => {
    const axiosService = new AxiosService();
    const dispatch = useDispatch();

    const [images, setImages] = useState([]);
    const [errors, setErrors] = useState({});
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [formData, setFormData] = useState({
        names: '',
        email: '',
        phone: '',
        requestReason: '',
        requestDetail: '',
        products: [],
    });

    const { items, linksPdfs } = useSelector((state) => state.appStore);

    const fetchItems = async () => {
        try {
            const response = await axiosService.getData('/products');
            const mappedItems = mapItems(response);
            dispatch(setItems(mappedItems));
        } catch (error) {
            console.error('Error fetching items:', error);
        } finally {
        }
    };

    const getDataFromUser = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) return;
            const response = await axiosService.getData('/auth/get-session');

            if (response?.user?.email) {
                setFormData({
                    ...formData,
                    names: response.user.fullname,
                    email: response.user.email,
                });
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    useEffect(() => {
        fetchItems();
        /* getDataFromUser(); */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const validateForm = () => {
        const formErrors = {};

        // Validación del nombre
        if (!formData.names) {
            formErrors.names = 'Este campo es requerido';
        } else if (formData.names.length < 3) {
            formErrors.names = 'El nombre debe tener al menos 3 caracteres';
        }

        // Validación del email
        const regexEmail =
            /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
        const regexItau =
            /^[A-Za-z0-9._%+-]+@(itau\.co|ariadnacg\.com|wdgroup\.co)$/;

        if (!formData.email) {
            formErrors.email = 'Este campo es requerido';
        } else if (
            !regexEmail.test(formData.email) ||
            !regexItau.test(formData.email)
        ) {
            formErrors.email = 'El email no es válido';
        }

        // Otras validaciones
        if (!formData.phone) formErrors.phone = 'Este campo es requerido';
        if (formData.products.length === 0)
            formErrors.products = 'Este campo es requerido';
        if (formData.requestReason === '0')
            formErrors.requestReason = 'Este campo es requerido';
        if (!formData.requestDetail)
            formErrors.requestDetail = 'Este campo es requerido';

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) return;

        if (images.length === 0) {
            timerAlert(
                'Error',
                '¡Debes subir al menos una imagen de soporte!',
                'error'
            );
            return;
        }

        confirmAlert(
            'Confirmación',
            '¿Estás seguro de enviar el formulario?',
            'warning'
        ).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    setIsLoadingData(true);
                    await createReturn(formData);
                } catch (error) {
                    console.error('Error al enviar el formulario:', error);
                } finally {
                    setIsLoadingData(false);
                }
            }
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'phone' && (!/^\d*$/.test(value) || value.length > 10))
            return;
        if (name === 'names' && value.length > 256) return;
        if (name === 'requestDetail' && value.length > 800) return;

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleMultiSelectChange = (e) => {
        const options = Array.from(e.target.selectedOptions);
        const values = options.map((option) => option.value);
        setFormData({
            ...formData,
            [e.target.name]: values,
        });
    };

    const createReturn = async (data) => {
        const form = new FormData();

        form.append('names', data.names);
        form.append('email', data.email);
        form.append('phone', data.phone);
        form.append('requestReason', data.requestReason);
        form.append('requestDetail', data.requestDetail);
        form.append('products', data.products);

        images.forEach((image) => {
            form.append('file', image);
        });


        try {
            const response = await axiosService.createFormData(
                '/returns/new-return',
                form
            );
            if (response.status === 201) {
                timerAlert(
                    '¡Solicitud de garantía enviada!',
                    'Verifica tu correo corporativo para más información',
                    'success'
                );
                setFormData({
                    names: '',
                    email: '',
                    phone: '',
                    requestReason: '',
                    requestDetail: '',
                    products: [],
                });

                setImages([]);
            } else {
                console.error('Error en la respuesta:', response);
            }
        } catch (error) {
            console.error('Error enviando el formulario:', error);
            timerAlert('Error', 'Error al enviar el formulario', 'error');
        }
    };

    return (
        <div className="form_req_main_container" id="devoluciones">
            <div className="form_req_container">
                <div className="section_container">
                    <div className="left">
                        <form
                            className="form-container"
                            onSubmit={handleSubmit}
                        >
                            <div
                                className={`form-group ${
                                    errors.names ? 'error' : ''
                                }`}
                            >
                                <input
                                    type="text"
                                    placeholder=" "
                                    id="names"
                                    name="names"
                                    value={formData.names}
                                    onChange={handleChange}
                                    maxLength="70"
                                />
                                <label htmlFor="names">
                                    Nombres y Apellidos*
                                </label>
                                {errors.names && (
                                    <span className="error-message">
                                        {errors.names}
                                    </span>
                                )}
                            </div>
                            <div
                                className={`form-group ${
                                    errors.email ? 'error' : ''
                                }`}
                            >
                                <input
                                    /* disabled */
                                    type="email"
                                    placeholder=" "
                                    id="email"
                                    name="email"
                                    maxLength="70"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                                <label htmlFor="email">
                                    Correo corporativo*
                                </label>
                                {errors.email && (
                                    <span className="error-message">
                                        {errors.email}
                                    </span>
                                )}
                            </div>
                            <div
                                className={`form-group ${
                                    errors.phone ? 'error' : ''
                                }`}
                            >
                                <input
                                    type="text"
                                    placeholder=" "
                                    id="phone"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                />
                                <label htmlFor="phone">Teléfono*</label>
                                {errors.phone && (
                                    <span className="error-message">
                                        {errors.phone}
                                    </span>
                                )}
                            </div>
                            <div
                                className={`form-group ${
                                    errors.products ? 'error' : ''
                                }`}
                            >
                                <Tooltip
                                    txtHover={'Producto(s) involucrado(s) '}
                                    position={'bottom'}
                                >
                                    <p>Para seleccionar múltiples opciones:</p>
                                    <ul>
                                        <li>
                                            En Windows: mantenga presionada la
                                            tecla de control (ctrl).
                                        </li>
                                        <li>
                                            En Mac: mantenga presionada la tecla
                                            de comando.
                                        </li>
                                    </ul>
                                </Tooltip>
                                <select
                                    id="products"
                                    name="products"
                                    multiple
                                    value={formData.products}
                                    onChange={handleMultiSelectChange}
                                >
                                    <option value="0" disabled>
                                        - Seleccione uno o varios -
                                    </option>
                                    {items.map((item) => (
                                        <option key={item.id} value={item.name}>
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                                {errors.products && (
                                    <span className="error-message">
                                        {errors.products}
                                    </span>
                                )}
                            </div>
                            <div
                                className={`form-group ${
                                    errors.requestReason ? 'error' : ''
                                }`}
                            >
                                <label
                                    className="not-floating"
                                    htmlFor="requestReason"
                                >
                                    Motivo de la garantía*
                                </label>

                                <select
                                    id="requestReason"
                                    name="requestReason"
                                    value={formData.requestReason}
                                    onChange={handleChange}
                                >
                                    <option value="" disabled>
                                        - Seleccione motivo de la garantía -
                                    </option>
                                    <option value="Defecto de fabricación">Defecto de fabricación</option>
                                </select>

                                {errors.requestReason && (
                                    <span className="error-message">
                                        {errors.requestReason}
                                    </span>
                                )}
                            </div>
                            <div
                                className={`form-group detail ${
                                    errors.requestDetail ? 'error' : ''
                                }`}
                            >
                                <textarea
                                    placeholder="Detalle de la solicitud*"
                                    id="requestDetail"
                                    name="requestDetail"
                                    value={formData.requestDetail}
                                    onChange={handleChange}
                                    maxLength="350"
                                ></textarea>
                                <label htmlFor="requestDetail">
                                    Detalle de la solicitud*
                                </label>
                                {errors.requestDetail && (
                                    <span className="error-message">
                                        {errors.requestDetail}
                                    </span>
                                )}
                            </div>

                            <div
                                className={`form-group ${
                                    errors.requestReason ? 'error' : ''
                                }`}
                            >
                                <label
                                    className="not-floating"
                                    htmlFor="requestReason"
                                >
                                    Imágenes de soporte*
                                </label>

                                <ImageUpload
                                    images={images}
                                    setImages={setImages}
                                />
                                {errors.requestReason && (
                                    <span className="error-message">
                                        {errors.requestReason}
                                    </span>
                                )}
                            </div>

                            {isLoadingData ? (
                                <Spinner />
                            ) : (
                                <button className="submit-button" type="submit">
                                    Enviar ahora
                                </button>
                            )}
                        </form>
                    </div>
                    <div className="right">
                        <p className="title">Proceso para garantías:</p>
                        <p className="descr">
                        <strong>Importante:</strong> No se aceptan solicitudes de cambio asociadas a talla, color o referencia. La garantía podrá ser solicitada únicamente por una falla en la calidad del producto y no por el uso indebido de este.
                            <span
                                className="link"
                                onClick={() => {
                                    if (linksPdfs['documento_3'][0].url) {
                                        window.open(
                                            `${process.env.NEXT_PUBLIC_API_URL}/${linksPdfs['documento_3'][0].url} `,
                                            '_blank'
                                        );
                                    }
                                }}
                            >
                                Política de devoluciones
                            </span>
                        </p>
                        <div className="item_container">
                            <div className="item_number">
                                <span>1</span>
                            </div>
                            <div className="text_container">
                                {/* <p className="title_item">
                                    Lorem ipsum dolor sit ame.
                                </p> */}
                                <p className="descr">
                                Diligencia el formulario dentro de los cinco (5) días hábiles siguientes tras haber recibido tu compra.
                                </p>
                            </div>
                        </div>
                        <div className="item_container">
                            <div className="item_number">
                                <span>2</span>
                            </div>
                            <div className="text_container">
                                {/* <p className="title_item">
                                    Lorem ipsum dolor sit ame.
                                </p> */}
                                <p className="descr">
                                Recibe respuesta a tu correo corporativo, en los dos (2) días hábiles siguientes al envío de tu solicitud. La respuesta será viable o no viable según el caso.
                                </p>
                            </div>
                        </div>
                        <div className="item_container">
                            <div className="item_number">
                                <span>3</span>
                            </div>
                            <div className="text_container">
                                {/* <p className="title_item">
                                    Lorem ipsum dolor sit ame.
                                </p> */}
                                <p className="descr">
                                Si la garantía es viable, envía el producto por cualquier transportadora (costo asumido por el ituber) y recibe el nuevo artículo dentro de los siguientes 8 días hábiles. Llegará al lugar inicial del pedido.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormRequest;
