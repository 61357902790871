'use client';
import Image from 'next/image';
import '../../scss/pages/home/accordion.scss';
import { useEffect, useRef, useState } from 'react';
import AxiosService from '@/app/services/AxiosServices';
import DOMPurify from 'dompurify';
import { Spinner } from '../spinner/Spinner';

const CustomIcon = () => (
    <svg
        className="scroll-down-icon"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="78"
        height="78"
        viewBox="0 0 78 78"
    >
        <defs>
            <filter
                id="Ellipse_1244"
                x="0"
                y="0"
                width="78"
                height="78"
                filterUnits="userSpaceOnUse"
            >
                <feOffset dy="3" input="SourceAlpha" />
                <feGaussianBlur stdDeviation="5" result="blur" />
                <feFlood floodOpacity="0.302" />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
            </filter>
        </defs>
        <g
            id="Component_23_1"
            data-name="Component 23 – 1"
            transform="translate(15 12)"
        >
            <g
                transform="matrix(1, 0, 0, 1, -15, -12)"
                filter="url(#Ellipse_1244)"
            >
                <circle
                    id="Ellipse_1244-2"
                    data-name="Ellipse 1244"
                    cx="24"
                    cy="24"
                    r="24"
                    transform="translate(15 12)"
                    fill="#fff"
                />
            </g>
            <path
                id="Icon_ion-ios-arrow-back"
                data-name="Icon ion-ios-arrow-back"
                d="M14.357,15.2l6.821-6.816A1.288,1.288,0,0,0,19.354,6.57l-7.728,7.723a1.286,1.286,0,0,0-.038,1.776l7.76,7.776a1.288,1.288,0,1,0,1.825-1.819Z"
                transform="translate(8.793 42.496) rotate(-90)"
                fill="#ff6201"
            />
        </g>
    </svg>
);

const Accordion = () => {
    const accordionRef = useRef(null);
    const axiosService = new AxiosService();
    const [faqs, setFaqs] = useState([]);
    const [isLoadingData, setIsLoadingData] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoadingData(true);
                const response = await axiosService.getData('/faqs');
                setFaqs(response);
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoadingData(false);
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleScrollDown = () => {
        if (accordionRef.current) {
            accordionRef.current.scrollBy({
                top: 100, 
                behavior: 'smooth',
            });
        }
    };

    return (
        <div className="accordion_main_container" id="faq">
            <div className="accordion_container">
                <div className="left">
                    <Image
                        src="/hombre-Saco.png"
                        alt="accordion"
                        width={533}
                        height={549}
                    />
                </div>

                <div className="right">
                    <h3 className="title">Preguntas frecuentes</h3>

                    <div
                        onClick={handleScrollDown}
                        style={{ cursor: 'pointer' }}
                    >
                        <CustomIcon />
                    </div>

                    <div
                        className="accordion"
                        id="accordionExample"
                        ref={accordionRef}
                    >
                        {isLoadingData ? (
                            <Spinner />
                        ) : (
                            <>
                                {faqs && faqs.length > 0 ? (
                                    faqs.map((faq, index) => (
                                        <div
                                            className="accordion-item"
                                            key={faq.id}
                                        >
                                            <h2
                                                className="accordion-header"
                                                id={`heading${index}`}
                                            >
                                                <button
                                                    className={`accordion-button ${
                                                        index !== 0
                                                            ? 'collapsed'
                                                            : ''
                                                    }`}
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={`#collapse${index}`}
                                                    aria-expanded={index === 0}
                                                    aria-controls={`collapse${index}`}
                                                    dangerouslySetInnerHTML={{
                                                        __html: DOMPurify.sanitize(
                                                            faq?.title
                                                        ),
                                                    }}
                                                />
                                            </h2>
                                            <div
                                                id={`collapse${index}`}
                                                className={`accordion-collapse collapse ${
                                                    index === 0 ? 'show' : ''
                                                }`}
                                                aria-labelledby={`heading${index}`}
                                                data-bs-parent="#accordionExample"
                                            >
                                                <div
                                                    className="accordion-body"
                                                    dangerouslySetInnerHTML={{
                                                        __html: DOMPurify.sanitize(
                                                            faq?.content
                                                        ),
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className="mt-5 pt-5 mb-5 text-center">
                                        <h2>No hay preguntas frecuentes</h2>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Accordion;
