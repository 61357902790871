'use client';
import Image from 'next/image';
import '../../scss/pages/home/banner.scss';
import { useRouter } from 'next/navigation';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { setPositionAction } from '@/app/redux.actions/items.actions';

const Banner = () => {
    const router = useRouter();
    const dispatch = useDispatch();
    const { isAuth } = useSelector((state) => state.appStore);
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);
    }, []);


    const goToRegister = () => {
        router.push('/auth');
        dispatch(setPositionAction('signup'))
    };

    const goToLogin = () => {
        router.push('/auth');
        dispatch(setPositionAction('signin'))
    };

    return (
        <div className="main_banner_container">
            <div className='image_mobile'>
                <Image src="/banner-mobile-itau.png" alt="banner" width={414} height={542} />
            </div>
            <div className='image_desktop'>
                <Image src="/banner_1.png" alt="banner" width={1920} height={500} />
            </div>
            <div className="info_container">
                <h1>Tienda naranja</h1>
                <h2>
                    Lleva nuestra marca <span>contigo</span>
                </h2>
                {
                    isMounted && !isAuth ? (
                        <div className="buttons_container">
                            <button onClick={goToRegister}>Regístrate</button>
                            <button onClick={goToLogin}>Inicia sesión</button>
                        </div>
                    ) : null
                }
            </div>
        </div>
    );
};

export default Banner;
